<template>
  <div class="p-3">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Profile",
  setup() {
    const items = [
      { label: "Info", icon: "pi pi-fw pi-home", to: "/profile" },
      {
        label: "Services",
        icon: "pi pi-fw pi-calendar",
        to: "/profile/services",
      },
      {
        label: "Locations",
        icon: "pi pi-fw pi-pencil",
        to: "/profile/locations",
      },
      {
        label: "Availability",
        icon: "pi pi-fw pi-file",
        to: "/profile/availability",
      },
    ];

    return {
      items,
    };
  },
};
</script>
